import apiTopicConfig from '@/api/topicConfig'
import * as types from '@/store/mutation-types'

interface State {
  keySearchLists: object[],
  updateProjInfo: object
}

let state: State = {
  keySearchLists: [],
  updateProjInfo: {}
}

const getters = {
  keySearchLists: (state: State) => state.keySearchLists,
  updateProjInfo: (state: State) => state.updateProjInfo
}

let mutations = {
  [types.TOPIC_CONFIG_LISTS] (state: State, data) {
    state.keySearchLists = data.data
  },
  [types.TOPIC_NEW_INFO] (state: State, data) {
    state.updateProjInfo = data.data
  }
}

let actions = {
  findUserBeLeader ({ commit }: any, req: any) {
    let params = req.params
    return apiTopicConfig.findUserBeLeader(params).then(data => {
      commit(types.TOPIC_CONFIG_LISTS, { data })
    })
  },
  addLeaderToProject ({ commit }: any, req: any) {
    let params = req.params
    return apiTopicConfig.addLeaderToProject(params)
  },
  delLeaderToProject ({ commit }: any, req: any) {
    let params = req.params
    return apiTopicConfig.delLeaderToProject(params)
  },
  updateProject ({ commit }: any, req: any) {
    let params = req.params
    return apiTopicConfig.updateProject(params).then(data => {
      commit(types.TOPIC_NEW_INFO, { data })
    })
  },
  createAnnounce ({ commit }: any, req: any) {
    let params = req.params
    return apiTopicConfig.createAnnounce(params)
  }
}

const TopicConfig = ({
  state,
  getters,
  actions,
  mutations
})

export default TopicConfig
