import api from '@/common/api'

/**
 * stats case / report
 * @param {*} params
 */
export default {
  caseDetailReport (params) {
    return api.get(`/projects/${params.projectId}/stats/case-detail`).then((res) => {
      return res.data.data
    })
  }
}
