// common
export let COMMON_TOKEN_CHANGE = 'COMMON_TOKEN_CHANGE'
export let COMMON_ACCOUNT = 'COMMON_ACCOUNT'

// dashboard
export let DASHBOARD_CASE = 'DASHBOARD_CASE'
export let DASHBOARD_DOCTOR = 'DASHBOARD_DOCTOR'
export let DASHBOARD_ANNOUNCE = 'DASHBOARD_ANNOUNCE'
export let DASHBOARD_DETAIL = 'DASHBOARD_DETAIL'
export let ASIDE_CONFIG = 'ASIDE_CONFIG'
export let PROJECT_GROUPS = 'PROJECT_GROUPS'

// members
export let MEMBERS_LIST = 'MEMBERS_LIST'
export let GROUP_LIST = 'GROUP_LIST'
export let UPDATEUSER = 'UPDATEUSER'
export let FORBIDDENUSERS = 'FORBIDDENUSERS'
export let CHANGEROLE = 'CHANGEROLE'
export let USERDETAIL = 'USERDETAIL'

// case
export let MEDICALCASE_APPROVED = 'MEDICALCASE_APPROVED'
export let MEDICALCASE_DETAIL = 'MEDICALCASE_DETAIL'
export let MEDICALCASE_CREATE = 'MEDICALCASE_CREATE'

// report
export let REPORT_CASE_DETAIL = 'REPORT_CASE_DETAIL'

// export case
export let ADVANCED_SEARCH_FIELD = 'ADVANCED_SEARCH_FIELD'
export let ADVANCED_SEARCH_LIST = 'ADVANCED_SEARCH_LIST'
export let DO_EXPORT_URL = 'DO_EXPORT_URL'
export let EXPORT_LOG = 'EXPORT_LOG'

// template
export let GET_TEMPLATE = 'GET_TEMPLATE'
export let ADD_FORM_GROUP = 'ADD_FORM_GROUP'
export let UDDATE_FORM_GROUP_FORM_FIELD = 'UDDATE_FORM_GROUP_FORM_FIELD'

// add topic
export let USER_PROFILE = 'USER_PROFILE'
export let USER_INFO = 'USER_INFO'
export let COPY_INFO = 'COPY_INFO'

// topic config
export let TOPIC_CONFIG_LISTS = 'TOPIC_CONFIG_LISTS'
export let TOPIC_NEW_INFO = 'TOPIC_NEW_INFO'

// img label
export let IMG_LABEL_LISTS = 'IMG_LABEL_LISTS'
export let IMG_LABEL_DCM_DETAIL = 'IMG_LABEL_DCM_DETAIL'

// wheel
export let WHEEL_LISTS = 'WHEEL_LISTS'
export let WHEEL_KEYWORD_LISTS = 'WHEEL_KEYWORD_LISTS'

// dataView 数据概览
export let DATA_VIEW_CASE = 'DATA_VIEW_CASE'
export let DATA_VIEW_USER = 'DATA_VIEW_USER'
export let DATA_VIEW_CLEAR = 'DATA_VIEW_CLEAR'

// import
export let DOWNLOAD_IMPORT_TEMPLETE = 'DOWNLOAD_IMPORT_TEMPLETE'

