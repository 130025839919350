import apiCase from '@/api/imgLabel'
import * as types from '@/store/mutation-types'

interface State {
  dcmLists: object[],
  dcmTotal: number,
  dcmFields: any[],
  dcmDetail: any[],
  dcmAsideLists: any[]
}

let state: State = {
  dcmLists: [],
  dcmTotal: 0,
  dcmFields: [],
  dcmDetail: [],
  dcmAsideLists: []
}

const getters = {
  dcmLists: (state: State) => state.dcmLists,
  dcmTotal: (state: State) => state.dcmTotal,
  dcmFields: (state: State) => state.dcmFields,
  dcmDetail: (state: State) => state.dcmDetail,
  dcmAsideLists: (state: State) => state.dcmAsideLists
}

let mutations = {
  [types.IMG_LABEL_LISTS] (state: State, data) {
    let res = data.data
    state.dcmTotal = res.total
    state.dcmLists = res.items,
    state.dcmFields = res.fields
  },
  [types.IMG_LABEL_DCM_DETAIL] (state: State, data) {
    let res = data.data
    state.dcmDetail = res
    state.dcmAsideLists = res.fields
  }
}

let actions = {
  getDcmLists ({ commit }: any, req: any) {
    let params = req.params
    return apiCase.getDcmLists(params).then(data => {
      commit(types.IMG_LABEL_LISTS, { data })
    })
  },
  dcmCaseDetail ({ commit }: any, req: any) {
    let params = req.params
    return apiCase.dcmCaseDetail(params).then(data => {
      commit(types.IMG_LABEL_DCM_DETAIL, { data })
    })
  },
  dcmLabels ({ commit }: any, req: any) {
    let params = req.params
    return apiCase.dcmLabels(params)
  },
  dcmLabelStatus ({ commit }: any, req: any) {
    let params = req.params
    return apiCase.dcmLabelStatus(params)
  }
}

const MedicalCase = ({
  state,
  getters,
  actions,
  mutations
})

export default MedicalCase
