<template>
  <div class="img-and-operate-container">
    <div class="operation-btn-area common-second-background-css">
      <div class="btn-area-content">
        <div class="operation-btn" @click.stop="getOperationType('isDragImg')">
          <div>
            <div class="opt-btn-class">
              <img src="./img/icon/icon-tuozhuai.png" alt="move icon">
            </div>
          </div>
          <p>拖拽</p>
        </div>
        <div class="operation-btn" @click.stop="scaleImg('scaleBig')">
          <div>
            <div class="opt-btn-class">
              <img src="./img/icon/icon-fangda.png" alt="big icon">
            </div>
          </div>
          <p>放大</p>
        </div>
        <div class="operation-btn" @click.stop="scaleImg('scaleSmall')">
          <div>
            <div class="opt-btn-class">
              <img src="./img/icon/icon-suoxiao.png" alt="small icon">
            </div>
          </div>
          <p>缩小</p>
        </div>
        <div class="operation-btn" @click.stop="getOperationType('isRanging')">
          <div>
            <div class="opt-btn-class">
              <img src="./img/icon/icon-celiang.png" alt="ruler icon">
            </div>
          </div>
          <p>测量</p>
        </div>
        <div class="operation-btn" @click.stop="getOperationType('isTagging')">
          <div>
            <div class="opt-btn-class">
              <img src="./img/icon/icon-biaoji.png" alt="mark icon">
            </div>
          </div>
          <p>标记</p>
        </div>
        <div class="operation-btn" @click.stop="getOperationType('isAngle')">
          <div>
            <div class="opt-btn-class">
              <img src="./img/icon/icon-angle.png" alt="mark icon">
            </div>
          </div>
          <p>角标</p>
        </div>
        <div class="operation-btn">
          <div class="slider-btn-content">
            <el-slider v-model="opcityNum"></el-slider>
          </div>
          <p>明暗调节</p>
        </div>
        <div class="operation-btn">
          <div class="slider-btn-content">
            <el-slider v-model="contrastNum"></el-slider>
          </div>
          <p>对比度调节</p>
        </div>
        <div class="operation-btn" @click.stop="saveLabelInfo">
          <div>
            <div class="opt-btn-class">
              <img src="./img/icon/icon-save.png" alt="mark icon">
            </div>
          </div>
          <p>保存标记</p>
        </div>
      </div>
      <div class="btn-area-content">
        <div class="operation-btn" @click.stop="showRawOut('raw')">
          <div>
            <div
              class="opt-btn-class"
              :style="`background-color: ${showRaw && !showOut ? '#29588E' : '#1C3F72'}`"
            >
              <img src="./img/icon/icon-yuantu.png" alt="ruler icon">
            </div>
          </div>
          <p>{{imageData.raw_title || '原图'}}</p>
        </div>
        <div class="operation-btn" @click.stop="showRawOut('out')">
          <div>
            <div
              class="opt-btn-class"
              :style="`background-color: ${showOut && !showRaw ? '#29588E' : '#1C3F72'}`"
            >
              <img src="./img/icon/icon-chulijieguo.png" alt="ruler icon">
            </div>
          </div>
          <p>{{imageData.out_title || '结果'}}</p>
        </div>
        <div class="operation-btn" @click.stop="showRawOut('both')">
          <div>
            <div
              class="opt-btn-class"
              :style="`background-color: ${showOut && showRaw ? '#29588E' : '#1C3F72'}`"
            >
              <img src="./img/icon/icon-yuantu&chulijieguo.png" alt="ruler icon">
            </div>
          </div>
          <p>{{imageData.raw_title || '原图'}} + {{imageData.out_title || '结果'}}</p>
        </div>
      </div>
    </div>
    <div class="detail-image" v-loading="loading">
      <div v-show="showRaw" :class="showOut && showRaw ? 'raw-image-list-box' : 'image-list-box'">
        <div class="image-detail-info-container">
          <div class="image-detail-info-title">{{imageData.raw_title || '原图'}}</div>
          <div class="image-detail-info-img-content">
            <div class="image-detail-info-img">
              <div
                @mousedown.stop="mouseDown"
                @mousemove.stop="mouseMove"
                @mouseup.stop="mouseUp"
                ref="imgContainer"
                class="img-container"
                :style="{
                  '-webkit-filter': 'opacity('+opcityNum+'%) contrast('+contrastNum +'%)',
                  'filter': 'opacity('+opcityNum+'%) contrast('+contrastNum +'%)',
                  'zoom': zoomNum,
                  'top': imgContainerTop+'px',
                  'left': imgContainerLeft+'px',
                  'cursor': isDragImg ? 'move' : ''
                }"
              >
                <img
                  draggable="false"
                  v-if="imageData && imageData.raw_img"
                  :src="(imageData && imageData.raw_img)"
                  alt="raw img"
                >
              </div>
            </div>
            <analysis :data="patientInfo"></analysis>
          </div>
        </div>
      </div>
      <div v-show="showOut" :class="showOut && showRaw ? 'out-image-list-box' : 'image-list-box'">
        <div class="image-detail-info-container">
          <div class="image-detail-info-title">{{imageData.out_title || '结果'}}</div>
          <div class="image-detail-info-img-content" 
            onselectstart="return false"
            @mousedown.stop="mouseDown"
            @mousemove.stop="mouseMove"
            @mouseup.stop="mouseUp"
            @dblclick="canvasClick">
            <div class="image-detail-info-img" ref="imgPositionDivBox">
              <div
                
                ref="imgContainer"
                class="img-container"
                :style="{
                  '-webkit-filter': 'opacity('+opcityNum+'%) contrast('+contrastNum +'%)',
                  'filter': 'opacity('+opcityNum+'%) contrast('+contrastNum +'%)',
                  'zoom': zoomNum,
                  'top': imgContainerTop+'px',
                  'left': imgContainerLeft+'px',
                  'cursor': isDragImg ? 'move' : ''
                }">
                <canvas
                  ref="canvas"
                  :width="imgWidth"
                  :height="imgHeight"
                ></canvas>
                <!--                   @mousedown.stop="mouseDown"
                  @mousemove.stop="mouseMove"
                  @mouseup.stop="mouseUp" -->
                <img
                  style="position: absolute,top: 0, left: 0"
                  v-show="false"
                  ref="img"
                  :src="(imageData && imageData.out_img)"
                  alt="out img"
                >
              </div>
              <div class="editor-background" v-if="showEditBox">
                <div class="editor-container">
                  <div class="editor-btn-area">
                    <img @click="addNewBox" src="./img/icon/icon-chakan.png" alt="hide icon">
                    <img @click="deleteBox" src="./img/icon/icon-shanchu.png" alt="ruler icon">
                  </div>
                  <div class="editor-box-des">
                    <textarea class="input-class" v-model="currentBoxDes" type="text-area"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <analysis @mousedown.stop.prevent="mouseDown" :data="patientInfo"></analysis>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import analysis from "./AnalysistText.vue";
import { setTimeout } from "timers";

export default {
  data() {
    return {
      opcityNum: 100,
      contrastNum: 100,
      zoomNum: 1,
      loading: false,
      currentModel: {
        originName: "",
        nextName: "",
        raw_img: "",
        out_img: ""
      },
      originImgInfo: {
        PatientName: "",
        rows: 0,
        columns: 0
      },
      showRaw: true,
      showOut: true,
      imgContainerTop: 0,
      imgContainerLeft: 0,
      box: {},
      boxContainerX: 0,
      boxContainerY: 0,
      boxContainerWidth: 0,
      boxContainerHeight: 0,
      boxPositionContainerDomX: 0,
      boxPositionContainerDomY: 0,
      boxPositionContainerDomWidth: 0,
      boxPositionContainerDomHeight: 0,
      x: 0,
      y: 0,
      ex: 0,
      ey: 0,
      diffX: 0,
      diffY: 0,

      isDragImg: false,

      boxList: [],
      isBox: false,
      newBox: false,
      moveBox: false,
      boxId: null,

      lineList: [],
      isLine: false,
      newLine: false,
      moveLine: false,
      lineId: null,

      angleList: [],
      isAngle: false,
      newAngle: false,
      moveAngle: false,
      angleId: null,

      targetIndex: 0,
      dbClicked: false,
      currentBoxDes: "",
      contentTxt: "",
      imgWidth: 512,
      imgHeight: 512,
      boxConerIndex: 0,
      angleConerIndex: 0,
      showEditBox: false,
      defaultProps: {
        imageData: {},
        patientInfo: {}
      }
    };
  },
  mounted() {
    this.getContainerBoxInfo();
    this.getContentTxt();
  },
  components: {
    analysis
  },
  props: {
    imageData: Object,
    patientInfo: Object
  },
  methods: {
    showRawOut(type) {
      if (type === "raw") {
        this.showOut = false;
        this.showRaw = true;
      } else if (type === "out") {
        this.showOut = true;
        this.showRaw = false;
      } else {
        this.showOut = true;
        this.showRaw = true;
      }
      this.getOperationType("isDragImg");
    },
    getOperationType(type) {
      this.isBox = false;
      this.isLine = false;
      this.isAngle = false;
      this.isDragImg = false;
      if (type === "isTagging") {
        this.isBox = true;
      } else if (type === "isRanging") {
        this.isLine = true;
      } else if (type === "isDragImg") {
        this.isDragImg = true;
      } else if (type === "isAngle") {
        this.isAngle = true;
      }
      this.getContainerBoxInfo();
    },
    scaleImg(type) {
      if (type === "scaleBig") {
        if (this.zoomNum < 2) {
          this.zoomNum += 0.2;
        }
      } else if (type === "scaleSmall") {
        if (this.zoomNum > 1) {
          this.zoomNum -= 0.2;
        }
      }
      this.getContainerBoxInfo()
    },

    // 内部方法
    getContainerBoxInfo() {
      let boxContainerDom = this.$refs["imgContainer"];
      this.boxContainerX = boxContainerDom["getBoundingClientRect"]().x * this.zoomNum;
      this.boxContainerY = boxContainerDom["getBoundingClientRect"]().y * this.zoomNum;
      this.boxContainerWidth = boxContainerDom["offsetWidth"];
      this.boxContainerHeight = boxContainerDom["offsetHeight"];

      let boxPositionContainerDom = this.$refs["imgPositionDivBox"];
      this.boxPositionContainerDomX = boxPositionContainerDom["getBoundingClientRect"]().x;
      this.boxPositionContainerDomY = boxPositionContainerDom["getBoundingClientRect"]().y;
      this.boxPositionContainerDomWidth = boxPositionContainerDom["offsetWidth"];
      this.boxPositionContainerDomHeight = boxPositionContainerDom["offsetHeight"];
    },

    getContentTxt() {
      let imgDom = this.$refs["img"];
      if (this.$refs["canvas"]) {
        this.contentTxt = this.$refs["canvas"]["getContext"]("2d");
        this.contentTxt.strokeStyle = "#42C8A8";
        this.contentTxt.fillStyle = "#42C8A8";
        imgDom.onload = () => {
          this.contentTxt.drawImage(
            imgDom,
            0,
            0,
            this.imgWidth,
            this.imgHeight
          );
          this.initData();
        };
      }
    },
    clearRect() {
      this.contentTxt.clearRect(0, 0, this.imgWidth, this.imgHeight);
    },

    drawBgImg() {
      let imgDom = this.$refs["img"];
      this.contentTxt.drawImage(imgDom, 0, 0, this.imgWidth, this.imgHeight);
    },

    initData(){
      this.clearRect();
      this.drawBgImg();
      this.batchDrawRect();
      this.batchDrawAngle();
      this.batchDrawLine();
    },

    batchDrawRect(){
      this.boxList.map(item=>{
        this.drawRect(item.x,item.y,item.ex,item.ey);
      })
    },
    batchDrawAngle(){
      this.angleList.map(item=>{
        this.drawAngle(item.x,item.y,item.cx,item.cy,item.ex,item.ey);
      })
    },
    batchDrawLine(){
      this.lineList.map(item=>{
        this.drawLine(item.x,item.y,item.ex,item.ey);
      })
    },

    drawRect(x, y, ex, ey) {
      let width = Math.abs(ex - x);
      let height = Math.abs(ey - y);
      let startX = x - ex < 0 ? x : ex;
      let startY = y - ey < 0 ? y : ey;
      this.contentTxt.beginPath();
      this.contentTxt.strokeRect(startX, startY, width, height);
      let percentData = this.patientInfo.PixelSpacing[0] || 1;
      let txt = Math.round(width*percentData*height*percentData) + ' mm²';
      this.contentTxt.strokeText(
        txt,
        this.centerText(txt, x, ex, "width"),
        this.centerText(txt, y, ey, "height")
      );

      this.drawCircle(x, y);
      this.drawCircle(x, ey);
      this.drawCircle(ex, y);
      this.drawCircle(ex, ey);
    },

    drawAngle(x, y, cx, cy, ex, ey) {
      this.contentTxt.beginPath();
      this.contentTxt.moveTo(x, y);
      this.contentTxt.lineTo(cx, cy);
      this.contentTxt.lineTo(ex, ey);
      this.contentTxt.stroke();

      this.drawCircle(x, y);
      this.drawCircle(cx, cy);
      this.drawCircle(ex, ey);

      this.drawAngleText(x, y, cx, cy, ex, ey);
    },

    drawLine(x, y, ex, ey) {
      this.contentTxt.beginPath();
      this.contentTxt.moveTo(x, y);
      this.contentTxt.lineTo(ex, ey);
      this.contentTxt.stroke();

      this.contentTxt.font = "12px";
      let txt = `${this.getLineLength(x, y, ex, ey)}mm`;
      this.contentTxt.strokeText(
        txt,
        this.centerText(txt, x, ex, "width"),
        this.centerText(txt, y, ey, "height")
      );
    },

    centerText(txt, start, end, type) {
      let halfTxtWidthOrHeight = 0;
      let centerNum = 0;
      if (this.contentTxt) {
        let startNum = start > end ? end : start;
        let endNum = start < end ? end : start;
        if (type === "width") {
          halfTxtWidthOrHeight = this.contentTxt.measureText(txt).width / 2;
        } else {
          halfTxtWidthOrHeight = 0;
        }
        return (
          startNum + Math.abs(endNum - startNum) / 2 - halfTxtWidthOrHeight
        );
      }
    },

    getLineLength(x, y, ex, ey) {
      let percentData = this.patientInfo.PixelSpacing[0] || 1;
      return Math.round(Math.sqrt(Math.pow(ey - y, 2) + Math.pow(ex - x, 2))*percentData);
    },

    pointInBox(pointX, pointY, x, y, ex, ey) {
      let width = Math.abs(ex - x);
      let height = Math.abs(ey - y);
      let startX = x - ex < 0 ? x : ex;
      let startY = y - ey < 0 ? y : ey;
      this.contentTxt.beginPath();
      this.contentTxt.rect(startX, startY, width, height);
      return this.contentTxt.isPointInPath(pointX, pointY);
    },

    pointInLine(pointX, pointY, x, y, ex, ey) {
      this.contentTxt.beginPath();
      this.contentTxt.moveTo(x, y);
      this.contentTxt.lineTo(ex, ey);
      return this.contentTxt.isPointInPath(pointX, pointY);
    },

    drawCircle(x, y) {
      this.contentTxt.beginPath();
      this.contentTxt.arc(x, y, 3, 0, Math.PI * 2);
      this.contentTxt.fill();
    },

    pointInEndCircle(x, y, circleCenterX, circleCenterY) {
      this.contentTxt.beginPath();
      this.contentTxt.arc(circleCenterX, circleCenterY, 3, 0, Math.PI * 2);
      return this.contentTxt.isPointInPath(x, y);
    },

    computeAngle(x, y, cx, cy, ex, ey) {
      let coner1 = Math.atan2(x-cx,y-cy)*(180/Math.PI);
      let coner2 = Math.atan2(ex-cx,ey-cy)*(180/Math.PI);
      let conerData = 0;
      if(coner1>=0 && coner2>=0 && coner1<180 && coner2<180){
        conerData = coner2>coner1 ? coner2-coner1 : coner1-coner2;
      }else if(coner1<0 && coner2<0){
        conerData = 360-Math.abs(coner2+coner1);
      }else{
        conerData = Math.abs(coner1) + Math.abs(coner2);
      }
      if(conerData>180){// 只能展示小于180度的角
        conerData = 360 - conerData;
      }
      return Math.round(conerData*10)/10;
    },

    drawAngleText(x, y, cx, cy, ex, ey) {
      let text = this.computeAngle(x,y,cx,cy,ex,ey) + '°';
      this.contentTxt.strokeText(text, cx + 10, cy - 5);
    },
    addNewBox() {
      if(this.dbClicked){
        this.boxList[this.boxId].des = this.currentBoxDes;
      }else{
        this.boxList.push({
          x: this.x / this.zoomNum,
          y: this.y / this.zoomNum,
          ex: this.ex,
          ey: this.ey,
          des: this.currentBoxDes
        });
      }
      this.showEditBox = false;
      this.currentBoxDes = "";
      this.dbClicked = false;
      this.boxId = null;
      this.initData();
    },
    cancelEditor() {
      this.tempBox = {};
      this.showEditBox = false;
      this.currentBoxDes = "";
    },
    deleteBox(){
      if(this.dbClicked){
        this.boxList.splice(this.boxId,1);
      }
      this.showEditBox = false;
      this.tempBox = {}
      this.currentBoxDes = "";
      this.dbClicked = false;
      this.boxId = null;
      this.initData();
    },
    mouseDown(e) {
      let canvasDom =this.$refs["canvas"], containerDom = this.$refs["imgContainer"];
      let targetDom = e.target;
      if (e.button === 0 && (targetDom === canvasDom || targetDom === containerDom)) {//如果是左键点击
        this.x = e.clientX - this.boxContainerX;
        this.y = e.clientY - this.boxContainerY;
        if (this.isDragImg) {
          let targetDiv = this.$refs.imgContainer;
          this.diffX = e.clientX - targetDiv.offsetLeft * this.zoomNum;
          this.diffY = e.clientY - targetDiv.offsetTop * this.zoomNum;
          this.canDragImg = true;
          this.x = e.clientX;
          this.y = e.clientY;
        } else if (this.isBox) {
          this.moveBox = false;
          for (let i = 0; i < this.boxList.length; i++) {
            let item = this.boxList[i];
            if (
              this.pointInEndCircle(
                this.x / this.zoomNum,
                this.y / this.zoomNum,
                item.x,
                item.y
              )
            ) {
              this.boxId = i;
              this.boxConerIndex = 1;
              this.moveBox = true;
            } else if (
              this.pointInEndCircle(
                this.x / this.zoomNum,
                this.y / this.zoomNum,
                item.x,
                item.ey
              )
            ) {
              this.boxId = i;
              this.boxConerIndex = 2;
              this.moveBox = true;
            } else if (
              this.pointInEndCircle(
                this.x / this.zoomNum,
                this.y / this.zoomNum,
                item.ex,
                item.y
              )
            ) {
              this.boxId = i;
              this.boxConerIndex = 3;
              this.moveBox = true;
            } else if (
              this.pointInEndCircle(
                this.x / this.zoomNum,
                this.y / this.zoomNum,
                item.ex,
                item.ey
              )
            ) {
              this.boxId = i;
              this.boxConerIndex = 4;
              this.moveBox = true;
            } else if (
              this.pointInBox(
                this.x / this.zoomNum,
                this.y / this.zoomNum,
                item.x,
                item.y,
                item.ex,
                item.ey
              )
            ) {
              this.boxId = i;
              this.moveBox = true;
              this.boxConerIndex = 0;
            }
          }
          if (this.moveBox) {
            this.x = e.clientX;
            this.y = e.clientY;
          } else {
            this.newBox = true;
          }
        } else if (this.isLine) {
          this.moveLine = false;
          for (let i = 0; i < this.boxList.length; i++) {
            let item = this.boxList[i];
            if (
              this.pointInLine(
                this.x / this.zoomNum,
                this.y / this.zoomNum,
                item.x,
                item.y,
                item.ex,
                item.ey
              )
            ) {
              this.lineId = i;
              this.moveLine = true;
            }
          }
          if (this.moveLine) {
            this.x = e.clientX;
            this.y = e.clientY;
          } else {
            this.newLine = true;
          }
        } else if (this.isAngle) {
          this.moveAngle = false;
          for (let i = 0; i < this.angleList.length; i++) {
            let item = this.angleList[i];
            if (
              this.pointInEndCircle(
                this.x / this.zoomNum,
                this.y / this.zoomNum,
                item.x,
                item.y
              )
            ) {
              this.angleId = i;
              this.moveAngle = true;
              this.angleConerIndex = 1;
              return;
            } else if (
              this.pointInEndCircle(
                this.x / this.zoomNum,
                this.y / this.zoomNum,
                item.cx,
                item.cy
              )
            ) {
              this.angleId = i;
              this.moveAngle = true;
              this.angleConerIndex = 2;
              return;
            } else if (
              this.pointInEndCircle(
                this.x / this.zoomNum,
                this.y / this.zoomNum,
                item.ex,
                item.ey
              )
            ) {
              this.angleId = i;
              this.moveAngle = true;
              this.angleConerIndex = 3;
              return;
            }
          }
          if (this.moveAngle) {
            this.x = e.clientX;
            this.y = e.clientY;
          } else {
            this.newAngle = true;
          }
        }
      }
    },

    mouseMove(e) {
      if (this.canDragImg) {
        this.imgContainerTop = (e.clientY - this.diffY) / this.zoomNum;
        this.imgContainerLeft = (e.clientX - this.diffX) / this.zoomNum;
      } else if (this.isBox) {
        if (this.newBox) {
          this.clearRect();
          this.drawBgImg();
          this.boxList.map(item => {
            this.drawRect(item.x, item.y, item.ex, item.ey);
          });
          this.drawRect(
            this.x / this.zoomNum,
            this.y / this.zoomNum,
            (e.clientX - this.boxContainerX) / this.zoomNum,
            (e.clientY - this.boxContainerY) / this.zoomNum
          );
          this.batchDrawAngle();
          this.batchDrawLine();
        } else if (this.moveBox) {
          let i = this.boxId;
          this.clearRect();
          this.drawBgImg();
          let distenceX = (e.clientX - this.x) / this.zoomNum;
          let distenceY = (e.clientY - this.y) / this.zoomNum;
          this.boxList.map((item, index) => {
            let x = item.x,
              y = item.y,
              ex = item.ex,
              ey = item.ey;
            if (index === this.boxId) {
              if (this.boxConerIndex === 0) {
                x = item.x + distenceX;
                y = item.y + distenceY;
                ex = item.ex + distenceX;
                ey = item.ey + distenceY;
              } else if (this.boxConerIndex === 1) {
                x = item.x + distenceX;
                y = item.y + distenceY;
              } else if (this.boxConerIndex === 2) {
                x = item.x + distenceX;
                ey = item.ey + distenceY;
              } else if (this.boxConerIndex === 3) {
                ex = item.ex + distenceX;
                y = item.y + distenceY;
              } else if (this.boxConerIndex === 4) {
                ex = item.ex + distenceX;
                ey = item.ey + distenceY;
              }
            }
            this.drawRect(x, y, ex, ey);
          });
          this.batchDrawAngle();
          this.batchDrawLine();
        }
      } else if (this.isLine) {
        if (this.newLine) {
          this.clearRect();
          this.drawBgImg();
          this.lineList.map(item => {
            this.drawLine(item.x, item.y, item.ex, item.ey);
          });
          this.drawLine(
            this.x / this.zoomNum,
            this.y / this.zoomNum,
            (e.clientX - this.boxContainerX) / this.zoomNum,
            (e.clientY - this.boxContainerY) / this.zoomNum
          );
          this.batchDrawAngle();
          this.batchDrawRect();
        } else if (this.moveLine) {
          // this.clearRect();
          // this.drawBgImg();
          // let distenceX = (e.clientX-this.x)/this.zoomNum
          // let distenceY = (e.clientY-this.y)/this.zoomNum
          // this.lineList.map((item,index)=>{
          //   let x = item.x, y = item.y, ex = item.ex, ey = item.ey;
          //   if(index === this.lineId){
          //     x = item.x+distenceX;
          //     y = item.y+distenceY;
          //     ex = item.ex+distenceX;
          //     ey = item.ey+distenceY;
          //   }
          //   this.drawLine(x,y,ex,ey);
          // })
          // this.batchDrawAngle();
          // this.batchDrawRect();
        }
      } else if (this.isAngle) {
        if (this.newAngle) {
          this.ex = (e.clientX - this.boxContainerX) / this.zoomNum;
          this.ey = (e.clientY - this.boxContainerY) / this.zoomNum;
          this.clearRect();
          this.drawBgImg();
          this.angleList.map(item => {
            this.drawAngle(
              item.x,
              item.y,
              item.cx,
              item.cy,
              item.ex,
              item.ey
            );
          });
          this.drawAngle(
            this.ex,
            this.y / this.zoomNum,
            this.x / this.zoomNum,
            this.ey,
            this.ex,
            this.ey
          );
          this.batchDrawLine();
          this.batchDrawRect();
        } else if (this.moveAngle) {
          this.ex = (e.clientX - this.boxContainerX) / this.zoomNum;
          this.ey = (e.clientY - this.boxContainerY) / this.zoomNum;
          this.clearRect();
          this.drawBgImg();
          this.angleList.map((item, index) => {
            let newX, newY, newCX, newCY, newEX, newEY;
            newX = item.x;
            newY = item.y;
            newCX = item.cx;
            newCY = item.cy;
            newEX = item.ex;
            newEY = item.ey;
            if (index === this.angleId) {
              if (this.angleConerIndex === 1) {
                newX = this.ex;
                newY = this.ey;
              } else if (this.angleConerIndex === 2) {
                newCX = this.ex;
                newCY = this.ey;
              } else {
                newEX = this.ex;
                newEY = this.ey;
              }
            }
            this.drawAngle(newX, newY, newCX, newCY, newEX, newEY);
          });
          this.batchDrawLine();
          this.batchDrawRect();
        }
      }
    },

    mouseUp(e) {
      if (this.canDragImg) {
        this.imgContainerTop = (e.clientY - this.diffY) / this.zoomNum;
        this.imgContainerLeft = (e.clientX - this.diffX) / this.zoomNum;
      } else if (this.isBox) {
        if (this.newBox) {
          this.ex = (e.clientX - this.boxContainerX) / this.zoomNum;
          this.ey = (e.clientY - this.boxContainerY) / this.zoomNum;
          this.showEditBox = true;
        } else if (this.moveBox) {
          let i = this.boxId;
          let distenceX = (e.clientX - this.x) / this.zoomNum;
          let distenceY = (e.clientY - this.y) / this.zoomNum;
          let item = this.boxList[i];
          if (this.boxConerIndex === 0) {
            this.boxList[i].x = item.x + distenceX;
            this.boxList[i].y = item.y + distenceY;
            this.boxList[i].ex = item.ex + distenceX;
            this.boxList[i].ey = item.ey + distenceY;
          } else if (this.boxConerIndex === 1) {
            this.boxList[i].x = item.x + distenceX;
            this.boxList[i].y = item.y + distenceY;
          } else if (this.boxConerIndex === 2) {
            this.boxList[i].x = item.x + distenceX;
            this.boxList[i].ey = item.ey + distenceY;
          } else if (this.boxConerIndex === 3) {
            this.boxList[i].ex = item.ex + distenceX;
            this.boxList[i].y = item.y + distenceY;
          } else if (this.boxConerIndex === 4) {
            this.boxList[i].ex = item.ex + distenceX;
            this.boxList[i].ey = item.ey + distenceY;
          }
        }
      } else if (this.isLine) {
        this.ex = (e.clientX - this.boxContainerX) / this.zoomNum;
        this.ey = (e.clientY - this.boxContainerY) / this.zoomNum;
        if (this.newLine) {
          this.lineList.push({
            x: this.x/this.zoomNum,
            y: this.y/this.zoomNum,
            ex: this.ex,
            ey: this.ey
          });
          this.initData()
        }
      } else if (this.isAngle) {
        this.ex = (e.clientX - this.boxContainerX) / this.zoomNum;
        this.ey = (e.clientY - this.boxContainerY) / this.zoomNum;
        if (this.newAngle) {
          this.angleList.push({
            x: this.ex,
            y: this.y / this.zoomNum,
            cx: this.x / this.zoomNum,
            cy: this.ey,
            ex: this.ex,
            ey: this.ey
          });
        } else if (this.moveAngle) {
          if (this.angleConerIndex === 1) {
            this.angleList[this.angleId].x = this.ex;
            this.angleList[this.angleId].y = this.ey;
          } else if (this.angleConerIndex === 2) {
            this.angleList[this.angleId].cx = this.ex;
            this.angleList[this.angleId].cy = this.ey;
          } else {
            this.angleList[this.angleId].ex = this.ex;
            this.angleList[this.angleId].ey = this.ey;
          }
        }
      }
      this.moveBox = false;
      this.moveLine = false;
      this.moveAngle = false;
      this.newBox = false;
      this.newLine = false;
      this.newAngle = false;
      this.canDragImg = false;
    },

    canvasClick(e){
      this.dbClicked = false;
      e.preventDefault();
      if(this.isBox && e.target === this.$refs['canvas']){
        let eventX = e.clientX - this.boxContainerX;
        let eventY = e.clientY - this.boxContainerY;
        for (let i = 0; i < this.boxList.length; i++) {
          let item = this.boxList[i];
          if (
            this.pointInBox(
              eventX / this.zoomNum,
              eventY / this.zoomNum,
              item.x,
              item.y,
              item.ex,
              item.ey
            )
          ) {
            this.boxId = i;
            this.boxConerIndex = 0;
            this.dbClicked = true;
          }
        }
        if(this.dbClicked){
          this.currentBoxDes = this.boxList[this.boxId].des;
          this.showEditBox = true;
        }
      }
    },

    saveLabelInfo() {
      if (this.boxList.length > 0 || this.lineList.length > 0 || this.angleList.length > 0) {
        let data = {
          boxList: this.boxList,
          lineList: this.lineList,
          angleList: this.angleList
        };
        this.$emit("saveLabelInfo", data);
      } else {
        this.$message.error("没有标注");
      }
    }
  },
  watch: {
    patientInfo(val, old) {
      // if (val !== old) {
      //   this.boxList=[];
      //   this.lineList=[];
      // }
    },
    imageData(val, old) {
      if (val !== old) {
        this.boxList = [];
        this.lineList = [];
        this.angleList = [];
        if (val.out_labels && val.out_labels.length > 0) {
          val.out_labels.map(item => {
            let { x, y, cx, cy, ex, ey, des } = item;
            if (item.type === "rectangle") {
              this.boxList.push({
                x: x,
                y: y,
                ex: ex,
                ey: ey,
                style: {
                  "z-index": this.boxList.length + 2,
                  position: "absolute",
                  top: (y - ey < 0 ? y : ey) + "px",
                  left: (x - ex < 0 ? x : ex) + "px",
                  width: Math.abs(ex - x) + "px",
                  height: Math.abs(ey - y) + "px"
                },
                des: des
              });
            } else if (item.type === "line") {
              this.lineList.push({
                x: x,
                y: y,
                ex: ex,
                ey: ey,
                des: des
              });
            } else if(item.type === "angle"){
              this.angleList.push({
                x: x,
                y: y,
                cx: cx,
                cy: cy,
                ex: ex,
                ey: ey,
                des: des
              });
            }
            
          });
        }
        this.initData();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.common-second-background-css {
  background-color: #152f5f;
  border-radius: 5px;
}
.img-and-operate-container {
  box-sizing: border-box;
  color: #fff;
  .operation-btn-area {
    height: 100px;
    display: flex;
    justify-content: space-between;
    .btn-area-content {
      display: flex;
      justify-content: flex-start;
      padding: 10px;
    }
    .operation-btn {
      text-align: center;
      font-size: 12px;
      padding-right: 10px;
      p {
        margin-top: 10px;
      }
    }
    .operation-btn:last-child {
      padding-right: 0;
    }
  }
  .opt-btn-class {
    width: 38px;
    height: 38px;
    margin: 0 auto;
    background-color: #1c3f72;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    img {
      width: 26px;
      height: 26px;
    }
  }
  .slider-btn-content {
    width: 100px;
    background-color: #1c3f72;
    border-radius: 5px;
    padding: 0 15px;
  }
  .detail-image {
    margin-top: 20px;
    color: #fff;
    display: flex;
    .image-list-box {
      overflow: hidden;
      width: 100%;
      background-color: #152f5f;
      border-radius: 5px;
    }
    .raw-image-list-box {
      width: 49%;
      background-color: #152f5f;
      border-radius: 5px;
    }
    .out-image-list-box {
      width: 49%;
      margin-left: 2%;
      background-color: #152f5f;
      border-radius: 5px;
    }
  }
  .image-detail-info-container {
    padding: 20px;
    height: 620px; //要改
    .image-detail-info-img-content {
      box-sizing: border-box;
      margin-top: 20px;
      padding: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: #000;
      position: relative;
      .image-detail-info-img {
        width: 512px;
        height: 512px;
        overflow: hidden;
        position: relative;
        .img-container {
          position: absolute;
          img{
            height: 512px;
            width: 512px;
          }
        }
        .editor-background {
          // 遮罩容器
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          height: 100%;
          width: 100%;
          background-color: rgba(66,200,168,.2);
          display: flex;
          justify-content: center;
          align-items: center;
          .editor-container {
            position: absolute;
            padding: 20px;
            background-color: rgba(66, 200, 168, 1);
            border-radius: 5px;
            font-size: 12px;
            .editor-btn-area {
              display: flex;
              justify-content: flex-end;
              img {
                margin-left: 5px;
                cursor: pointer;
              }
            }
            .input-class {
              margin-top: 10px;
              height: 100px;
              width: 150px;
              padding: 10px;
              color: #fff;
              border: 1px solid #fff;
              background-color: rgba(66, 200, 168, 0);
              line-height: 1.5em;
              border-radius: 5px;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}
</style>
