import api from '@/common/api'

/**
 * caseList
 * @param {*} params
 */
export default {
  getDcmLists (params) {
    return api.get(`/projects/${params.projectId}/dcm`, { params }).then((res) => {
      return res.data.data
    })
  },
  // dcm case详情
  dcmCaseDetail (params) {
    return api.get(`/projects/${params.projectId}/dcm/${params.caseId}`, { params }).then((res) => {
      return res.data.data
    })
  },
  // 保存标注
  dcmLabels (params) {
    return api.post(`/projects/${params.projectId}/dcm/${params.caseId}/files/${params.fieldId}/labels`, params.query).then((res) => {
      return res.data.data
    })
  },
  // 切换组件标注状态
  dcmLabelStatus (params) {
    return api.put(`/projects/${params.projectId}/dcm/${params.caseId}/label-status`, params).then((res) => {
      return res.data.data
    })
  }
}
