import api from '@/common/api'

/**
 * caseList
 * @param {*} params
 */
export default {
  // Advanced Search Fields
  advancedSearchFields (params) {
    return api.get(`/projects/${params.projectId}/fields/advanced-search`, { params }).then((res) => {
      return res.data.data
    })
  },
  advancedSearch (params) {
    return api.post(`/projects/${params.projectId}/cases/search/?status=approved&keyword=${params.keyword}&limit=${params.limit}&offset=${params.offset}`, params.query).then((res) => {
      return res.data.data
    })
  },
  doExport (params) {
    return api.post(`/projects/${params.projectId}/cases/export/?status=approved&keyword=${params.keyword}&type=${params.type}`, params.query, true).then((res) => {
      return res.data.data
    })
  },
  exportLog (params) {
    return api.get(`/projects/${params.projectId}/export/log?limit=${params.limit}&offset=${params.offset}`, params).then((res) => {
      return res.data.data
    })
  }
}
