import apiDashboard from '@/api/dashboard'
import * as types from '@/store/mutation-types'
import { log } from 'util'

interface State {
  history: object,
  status: any[],
  caseTotal: number,
  doctorTotal: number,
  doctorStatus: any[],
  latestAnno: any,
  proDetail: object,
  userCreaters: any[],
  asideConfigs: object,
  projGroups: any[],
}

let state: State = {
  history: {
    approved: [],
    created: [],
    series: []
  },
  status: [],
  caseTotal: 0,
  doctorTotal: 0,
  doctorStatus: [],
  latestAnno: null,
  proDetail: {},
  userCreaters: [],
  asideConfigs: {},
  projGroups: []
}

const getters = {
  history: (state: State) => state.history,
  status: (state: State) => state.status,
  caseTotal: (state: State) => state.caseTotal,
  doctorStatus: (state: State) => state.doctorStatus,
  doctorTotal: (state: State) => state.doctorTotal,
  latestAnno: (state: State) => state.latestAnno,
  proDetail: (state: State) => state.proDetail,
  userCreaters:  (state: State) => state.userCreaters,
  asideConfigs:  (state: State) => state.asideConfigs,
  projGroups: (state: State) => state.projGroups
}

let mutations = {
  [types.DASHBOARD_CASE] (state: State, data) {
    let res = data.data
    state.history = res.history
    state.status = res.status
    state.caseTotal = res.total
  },
  [types.DASHBOARD_DOCTOR] (state: State, data) {
    let res = data.data
    state.doctorStatus = res.status
    state.doctorTotal = res.total
  },
  [types.DASHBOARD_ANNOUNCE] (state: State, data) {
    state.latestAnno = data.data
  },
  [types.DASHBOARD_DETAIL] (state: State, data) {
    state.proDetail = data.data
    state.userCreaters = data.data.leaders
  },
  [types.ASIDE_CONFIG] (state: State, data) {
    state.asideConfigs = data.data
  },
  [types.PROJECT_GROUPS] (state: State, data) {
    state.projGroups = data.data
  }
}

let actions = {
  statsCase ({ commit }: any, req: any) {
    let params = req.params
    return apiDashboard.statsCase(params).then(data => {
      commit(types.DASHBOARD_CASE, { data })
    })
  },
  statsDoctor ({ commit }: any, req: any) {
    let params = req.params
    return apiDashboard.statsUser(params).then(data => {
      commit(types.DASHBOARD_DOCTOR, { data })
    })
  },
  LatestAnnouncement ({ commit }: any, req: any) {
    let params = req.params
    return apiDashboard.LatestAnnouncement(params).then(data => {
      commit(types.DASHBOARD_ANNOUNCE, { data })
    })
  },
  projectDetail ({ commit }: any, req: any) {
    let params = req.params
    return apiDashboard.projectDetail(params).then(data => {
      commit(types.DASHBOARD_DETAIL, { data })
    })
  },
  delLeader ({ commit }: any, req: any) {
    let params = req.params
    return apiDashboard.delLeader(params)
  },
  asideConfig ({ commit }: any, req: any) {
    return apiDashboard.asideConfig().then(data => {
      commit(types.ASIDE_CONFIG, { data })
    })
  },
  // projectGroups ({ commit }: any, req: any) {
  //   let params = req.params
  //   return apiDashboard.projectGroups(params).then(data => {
  //     commit(types.PROJECT_GROUPS, { data })
  //   })
  // },
  // addProjectGroups ({ commit }: any, req: any) {
  //   let params = req.params
  //   return apiDashboard.addProjectGroups(params)
  // },
  // delProjectGroups ({ commit }: any, req: any) {
  //   let params = req.params
  //   return apiDashboard.delProjectGroups(params)
  // }
}

const MedicalCase = ({
  state,
  getters,
  actions,
  mutations
})

export default MedicalCase
