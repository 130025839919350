import api from '@/common/api'

/**
 * stats case / user
 * @param {*} params
 */
export default {
  findUserBeLeader (params) {
    return api.get(`/projects/${params.projectId}/users/search?q=${params.key}`).then((res) => {
      return res.data.data
    })
  },
  addLeaderToProject (params) {
    return api.post(`/projects/${params.projectId}/leaders/${params.userId}`, params).then((res) => {
      return res.data.data
    })
  },
  delLeaderToProject (params) {
    return api.delete(`/projects/${params.projectId}/leaders/${params.userId}`, params).then((res) => {
      return res.data.data
    })
  },
  updateProject (params) {
    return api.put(`/projects/${params.projectId}`, params).then((res) => {
      return res.data.data
    })
  },
  createAnnounce (params) {
    return api.post(`/projects/${params.projectId}/announcements`, params).then((res) => {
      return res.data.data
    })
  }
}
