import api from '@/common/api'

/**
 * stats case / user
 * @param {*} params
 */
export default {
  copyProject (params) {
    return api.post(`/ops/projects/${params.projectId}/copy`, params).then((res) => {
      return res.data.data
    })
  },
  updateProjectTitle (params) {
    return api.put(`/ops/projects/${params.projectId}`, params).then((res) => {
      return res.data.data
    })
  },
  delProject (params) {
    return api.delete(`/ops/projects/${params.projectId}`, params).then((res) => {
      return res.data.data
    })
  },
  userProfile () {
    return api.get(`/profile`).then((res) => {
      return res.data.data
    })
  },
  findUser (params) {
    return api.get(`/ops/users/search?q=${params.key}`, params).then((res) => {
      return res.data.data
    })
  },
  addUserToProject (params) {
    return api.post(`/ops/projects/${params.projectId}/users/${params.userId}`, params).then((res) => {
      return res.data.data
    })
  },
  delUser (params) {
    return api.delete (`/ops/projects/${params.projectId}/users/${params.userId}`).then((res) => {
      return res.data.data
    })
  }
}
