import apiImport from '@/api/import'
import * as types from '@/store/mutation-types'

interface State {
  downloadImpTempUrl: string
}

const state: State = {
  downloadImpTempUrl: ''
}

const getters = {
  downloadImpTempUrl: (state: State) => state.downloadImpTempUrl
}

let mutations = {
  [types.DOWNLOAD_IMPORT_TEMPLETE] (state: State, data) {
    state.downloadImpTempUrl = data.data
  }
}

let actions = {
  doImport ({ commit }: any, req: any) {
    let params = req.params
    return apiImport.doImport(params)
  },
  downloadImpTemp ({ commit }: any, req: any) {
    let params = req.params
    return apiImport.downloadImpTemp(params).then(data => {
      commit(types.DOWNLOAD_IMPORT_TEMPLETE, { data })
    })
  }
}

const fileImport = ({
  state,
  getters,
  actions,
  mutations
})

export default fileImport
