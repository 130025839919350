import api from '@/common/api'

/**
 * stats case / user
 * @param {*} params
 */
export default {
  statsCase (params) {
    return api.get(`/projects/${params.projectId}/stats/case`).then((res) => {
      return res.data.data
    })
  },
  statsUser (params) {
    return api.get(`/projects/${params.projectId}/stats/user`).then((res) => {
      return res.data.data
    })
  },
  LatestAnnouncement (params) {
    return api.get(`/projects/${params.projectId}/announcements/latest`).then((res) => {
      return res.data.data
    })
  },
  projectDetail (params) {
    return api.get(`/projects/${params.projectId}`).then((res) => {
      return res.data.data
    })
  },
  delLeader (params) {
    return api.delete (`/projects/${params.projectId}/leaders/${params.leaderId}`).then((res) => {
      return res.data.data
    })
  },
  asideConfig () {
    return api.get (`/configuration`).then((res) => {
      return res.data.data
    })
  }
}
