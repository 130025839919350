import apiMembers from '@/api/members'
import * as types from '@/store/mutation-types'

interface State {
  membertotal: number,
  membersList: any[],
  grouptotal: number,
  groupList: any[],
  fromInfo: object,
  detailInfo: object
}

let state: State = {
  membertotal: 0,
  grouptotal: 0,
  membersList: [],
  groupList: [],
  fromInfo: {},
  detailInfo: {}
}

const getters = {
  membertotal: (state: State) => state.membertotal,
  grouptotal: (state: State) => state.grouptotal,
  membersList: (state: State) => state.membersList,
  groupList: (state: State) => state.groupList,
  fromInfo: (state: State) => state.fromInfo,
  detailInfo: (state: State) => state.detailInfo
}

let mutations = {
  [types.MEMBERS_LIST](state: State, data) {
    let res = data.data
    state.membersList = res.items
    state.membertotal = res.total
  },
  [types.USERDETAIL](state: State, data) {
    state.detailInfo = data.data
  },
  [types.GROUP_LIST](state: State, data) {
    let res = data.data
    state.groupList = res.items
    state.grouptotal = res.total
  },
  [types.FORBIDDENUSERS](state: State, data) {
  },
  [types.CHANGEROLE](state: State, data) {
  }
}

let actions = {
  usersList({ commit }: any, req: any) {
    let params = req.params
    return apiMembers.usersList(params).then(data => {
      commit(types.MEMBERS_LIST, { data })
    })
  },
  usersDetail({ commit }: any, req: any) {
    let params = req.params
    return apiMembers.usersDetail(params).then(data => {
      commit(types.USERDETAIL, { data, params })
    })
  },
  groupsList({ commit }: any, req: any) {
    let params = req.params
    return apiMembers.groupsList(params).then(data => {
      commit(types.GROUP_LIST, { data, params })
    })
  },
  usersUpdate({ commit }: any, req: any) {
    let params = req.params
    return apiMembers.usersUpdate(params)
  },
  profileUpdate({ commit }: any, req: any) {
    let params = req.params
    return apiMembers.profileUpdate(params)
  },
  forbiddenUser({ commit }: any, req: any) {
    let params = req.params
    return apiMembers.forbiddenUser(params).then(data => {
      commit(types.FORBIDDENUSERS, { data , params })
    })
  },
  changeRole({ commit }: any, req: any) {
    let params = req.params
    return apiMembers.changeRole(params).then(data => {
      commit(types.CHANGEROLE, { data, params })
    })
  },
  addUsers ({ commit }: any, req: any) {
    let params = req.params
    return apiMembers.addUser(params)
  }
}

const Members = ({
  state,
  getters,
  actions,
  mutations
})

export default Members
