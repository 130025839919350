import Vue from 'vue'
import Vuex from 'vuex'
import login from '@/store/modules/login'
import medicalCase from '@/store/modules/medicalCase'
import dashboard from '@/store/modules/dashboard'
import members from '@/store/modules/members'
import report from '@/store/modules/report'
import exportCase from '@/store/modules/export'
import tempConfig from '@/store/modules/tempConfig'
import topicAdd from '@/store/modules/topicAdd'
import topicConfig from '@/store/modules/topicConfig'
import imgLabel from '@/store/modules/imgLabel'
import wheel from '@/store/modules/wheel'
import fileImport from '@/store/modules/fileImport'
import dataView from '@/store/modules/dataView'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    login,
    medicalCase,
    dashboard,
    members,
    report,
    exportCase,
    tempConfig,
    topicConfig,
    topicAdd,
    imgLabel,
    wheel,
    fileImport,
    dataView
  },
  strict: false
  // plugins: debug ? [createLogger()] : []
})
