class Conf {
  TestUrl: string = 'https://test.myathena.cn/api'
  Timeout: number = 3000
  DownloadTimeout: number = 10 * 60 * 1000
  ExceptionCodeMsg: object = {
    'exception:400': '错误请求！',
    'exception:401': '未授权，请重新登录！',
    'exception:403': '拒绝访问，请先获取权限！',
    'exception:404': '请求页面不存在！',
    'exception:405': '请求方法未允许！',
    'exception:406': '无法访问服务器！',
    'exception:408': '请求超时了！',
    'exception:422': '请求参数有误！',
    'exception:500': '服务器出错了！',
    'exception:502': '服务器连接超时！',
    'exception:503': '服务不可用！',
    'exception:504': '网络超时了！'
  }
  DomainConf: Object = {
    "https://ideal.quancheng-ec.com": 'IdealLogin',
    "https://myathena.cn": 'Login',
    "https://test.myathena.cn": 'Login',
    "https://image.myathena.cn": 'ImageLogin'
  }
  imageDomation: string = 'image.myathena.cn'
  idealDomation: string = 'ideal.quancheng-ec.com'
  constructor() {}
  baseUrl() {
    const domation = window.location.host
    return process.env.NODE_ENV === 'production' ?  `//${domation}/api` : this.TestUrl
  }
  judgeDomation () {
    const domation = window.location.host
    if (domation === this.imageDomation) {
      return 'image'
    } else if (domation === this.idealDomation) {
      return 'ideal'
    } else {
      return 'myathena'
    }
  }
  loginTempSelect(componentName: string) {
    let origin = window.location.origin
    return this.DomainConf[origin] !== undefined ? this.DomainConf[origin] : componentName
  }
}

export default new Conf()
