import Vue from 'vue'
import Router from 'vue-router'
import bus from '@/common/bus'
import conf from '@/common/conf'

Vue.use(Router)

function _ (name: string) {
  return () => import(`@/views/${name}.vue`)
}
const routes = [
  {
    path: '/',
    name: 'login',
    hidden: true,
    component: _(conf.loginTempSelect('Login'))
  }, {
    path: '/forgetPassword',
    name: 'forget-password',
    hidden: true,
    component: _('ForgetPassword')
  }, {
    path: '/dashboard',
    name: 'dashboard-index',
    class: 'iconfont icon-shouye',
    meta: { requiresAuth: true },
    component: _('dashboard/index')
  }, {
    path: '/topic-add',
    name: 'topicAdd-index',
    hidden: true,
    component: _('topicAdd/index')
  }, {
    path: '/personal',
    name: 'personal-index',
    class: 'iconfont icon-gerenzhongxin',
    component: _('personal/index'),
    meta: { requiresAuth: true }
    // children: [{
    //   path: 'info',
    //   name: 'config-personal',
    //   component: _('personal/info')
    // },
    // {
    //   path: 'password',
    //   name: 'config-password',
    //   component: _('personal/password')
    // }]
  }, {
    path: '/member',
    name: 'member-index',
    class: 'iconfont icon-chengyuanguanli',
    component: _('member/index'),
    redirect: '/member/managers',
    meta: { requiresAuth: true },
    children: [{
      path: 'managers',
      name: 'member-managers',
      component: _('member/groups'),
      }, {
        path: 'groups',
        name: 'member-groups',
        component: _('member/groups')
      }, {
        path: 'detail/:id',
        name: 'member-detail',
        hidden: true,
        component: _('member/detail')
      }]
    }, {
      path: '/case',
      name: 'case-index',
      class: 'iconfont icon-shouye1',
      component: _('case/index'),
      redirect: '/case/pass',
      meta: { requiresAuth: true },
      children: [{
        path: 'pass',
        name: 'case-pass',
        component: _('case/pass')
      }, {
        path: 'check',
        name: 'case-check',
        component: _('case/check')
      }, {
        path: 'mine',
        name: 'case-mine',
        component: _('case/mine')
      }, {
        path: 'detail/:id',
        name: 'case-detail',
        hidden: true,
        component: _('case/detail')
      }, {
        path: 'add/:id',
        name: 'case-add',
        hidden: true,
        component: _('case/detail')
      }]
    }, {
      path: '/report',
      name: 'report-index',
      class: 'iconfont icon-baobiao-normal',
      component: _('report/index'),
      meta: { requiresAuth: true }
    }, {
      path: '/export',
      name: 'export-index',
      class: 'iconfont icon-daochu',
      redirect: '/export/search',
      meta: { requiresAuth: true },
      component: _('export/index'),
      children: [{
        path: 'search',
        name: 'export-search',
        component: _('export/search')
      }, {
        path: 'log',
        name: 'export-log',
        component: _('export/log')
      }]
    },
    {
      path: '/temp-config',
      name: 'temp-config-index',
      class: 'iconfont icon-set-configure',
      meta: { requiresAuth: true },
      component: _('tempConfig/index')
    },
    {
      path: '/topic-config',
      name: 'topic-config-index',
      class: 'iconfont icon-ic_configchecklist',
      meta: { requiresAuth: true },
      component: _('topicConfig/index')
    },
    {
      path: '/staff-account',
      name: 'staff-account-index',
      hidden: true,
      class: 'iconfont icon-set-configure',
      meta: { requiresAuth: true },
      component: _('staffAccount/index')
    },
    {
      path: '/data-overview',
      name: 'data-overview-index',
      hidden: true,
      class: 'iconfont icon-set-configure',
      meta: { requiresAuth: true },
      component: _('dataOverview/index')
    }
  ]
const router = new Router({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (localStorage.getItem("token") !== null && localStorage.getItem("projects") === null) {
    // 有token没有projects
    if (to.name === 'login') {
      router.push('/topic-add')
    }
  } else if (localStorage.getItem("token") !== null && localStorage.getItem("projects") !== null) {
    // 有token有projects
    if (to.name === 'login') {
      router.push('/dashboard')
    }
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem("token") === null) {
      bus.$emit('exception:401')
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router
