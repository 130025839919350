import apiWheel from '@/api/wheel'
import * as types from '@/store/mutation-types'

interface State {
  wheelTotal: number,
  wheelList: any[],
  wheelSearchLists: any[]
}

let state: State = {
  wheelTotal: 0,
  wheelList: [],
  wheelSearchLists: []
}

const getters = {
  wheelTotal: (state: State) => state.wheelTotal,
  wheelList: (state: State) => state.wheelList,
  wheelSearchLists: (state: State) => state.wheelSearchLists
}

let mutations = {
  [types.WHEEL_LISTS](state: State, data) {
    state.wheelList = data.data.items
    state.wheelTotal = data.data.total
  },
  [types.WHEEL_KEYWORD_LISTS] (state: State, data) {
    state.wheelSearchLists = data.data
  }
}

let actions = {
  wheelUsersList({ commit }: any, req: any) {
    let params = req.params
    return apiWheel.wheelUsersList(params).then(data => {
      commit(types.WHEEL_LISTS, { data })
    })
  },
  createWheelUser({ commit }: any, req: any) {
    let params = req.params
    return apiWheel.createWheelUser(params)
  },
  delWheelUser({ commit }: any, req: any) {
    let params = req.params
    return apiWheel.delWheelUser(params)
  },
  findUserToWheel ({ commit }: any, req: any) {
    let params = req.params
    return apiWheel.findUserToWheel(params).then(data => {
      commit(types.WHEEL_KEYWORD_LISTS, { data })
    })
  }
}

const Wheel = ({
  state,
  getters,
  actions,
  mutations
})

export default Wheel
