<template>

  <div class="analysis-text">
    <div class="top">
      <div class="left-top">
        <p>Columns*Rows: {{data.Columns + '*' +  data.Rows}}</p>
        <p>Series Number: {{data.SeriesNumber}}</p>
        <p>Protocol Name: {{data.protocolName}}</p>
      </div>
      <div class="right-top">
        <p>Patient's Name: {{data.PatientName}}</p>
        <p>Patient ID: {{data.PatientID}}</p>
        <p>Patient's Sex: {{data.PatientSex}}</p>
      </div>
    </div>
    <div class="bottom">
      <div class="left-bottom">
        <p>Slice Location: {{data.SliceLocation}}</p>
      </div>
      <div class="right-bottom">
        <p>Acquisition Date: {{data.AcquisitionDate}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'analysis',
  props: {
    data: Object
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.analysis-text {
  pointer-events: none;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  position: absolute;
  z-index: 999;
  padding: 4px;
  top: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
  p {
    text-align: left;
  }
  .right-top {
    p {
      text-align: right;
    }
  }
  .right-bottom {
    p {
      text-align: right;
    }
  }
  .top {
      display: flex;
      justify-content: space-between;
  }
  .bottom {
      display: flex;
      justify-content: space-between;
  }
}
</style>
