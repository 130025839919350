import bus from './bus'
import router from '@/router'
import conf from '@/common/conf'
import { Message } from 'element-ui'

class Msg {
  constructor () {
    this.eventBusHandler(conf.ExceptionCodeMsg)
    this.eventBusErrorHandler()
  }
  /**
   * 常见异常处理
   */
  eventBusHandler (msg: any) {
    for (let i in msg) {
      if (i === 'exception:401') {
        bus.$on('exception:401', () => {
          Message({
            message: msg[i],
            type: 'error'
          })
          router.push({
            path: '/'
          })
        })
      } else {
        bus.$on(i, (errInfo) => {
          let errorMsg = errInfo === undefined ? '' : errInfo
          Message({
            message: msg[i] + errorMsg,
            type: 'error'
          })
        })
      }
    }
  }

  /**
   * 带参数错误处理
   */
  eventBusErrorHandler () {
    bus.$on('error', (msg: any) => {
      Message({
        message: msg,
        type: 'error'
      })
    })
  }
}

export default Msg
