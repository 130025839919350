import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import ElementUi from 'element-ui'
import Msg from '@/common/msg'
import '@/assets/styles/common.scss'
import ImageLabel from '@/plugins/imageLabel/index'

let msg: Msg = new Msg()
Vue.use(ElementUi)
Vue.use(<any>msg)
Vue.use(ImageLabel)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
