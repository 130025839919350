import apiTemp from '@/api/tempConfig'
import * as types from '@/store/mutation-types'

interface State {
  tempInfo: Object
}

let state: State = {
  tempInfo: {}
}

const getters = {
  tempInfo: (state: State) => state.tempInfo
}

let mutations = {
  [types.GET_TEMPLATE] (state: State, data) {
    state.tempInfo = []
    let res = data.data
    state.tempInfo = res
  },
  [types.ADD_FORM_GROUP] (state: State, data) {
  }
}

let actions = {
  tempList ({ commit }: any, req: any) {
    let params = req.params
    return apiTemp.getTemplate(params).then(data => {
      commit(types.GET_TEMPLATE, { data })
    })
  },
  editTempName ({ commit }: any, req: any) {
    let params = req.params
    return apiTemp.editTempName(params).then(data => {
      commit(types.GET_TEMPLATE, { data })
    })
  },
  addFormGroup ({ commit }: any, req: any) {
    let params = req.params
    return apiTemp.addFormGroup(params)
  },
  updateFormGroup ({ commit }: any, req: any) {
    let params = req.params
    return apiTemp.updateFormGroup(params)
  },
  addFormGroupForm ({ commit }: any, req: any) {
    let params = req.params
    return apiTemp.addFormGroupForm(params)
  },
  updateFormGroupForm ({ commit }: any, req: any) {
    let params = req.params
    return apiTemp.updateFormGroupForm(params)
  },
  updateFormGroupFormField ({ commit }: any, req: any) {
    let params = req.params
    return apiTemp.updateFormGroupFormField(params)
  },
  delFormGroup ({ commit }: any, req: any) {
    let params = req.params
    return apiTemp.delFormGroup(params)
  },
  sortFormGroupForm ({ commit }: any, req: any) {
    let params = req.params
    return apiTemp.sortFormGroupForm(params)
  },
  copyGroup ({ commit }: any, req: any) {
    let params = req.params
    return apiTemp.copyGroup(params)
  },
  copyGroupForm ({ commit }: any, req: any) {
    let params = req.params
    return apiTemp.copyGroupForm(params)
  }
}
const MedicalCase = ({
  state,
  getters,
  actions,
  mutations
})

export default MedicalCase
