import api from '@/common/api'
/**
 * caseList
 * @param {*} params
 */

export default {
  wheelUsersList(params) {
    return api.get(`/ops/users/pagination`, { params }).then((res) => {
      return res.data.data
    })
  },
  createWheelUser (params) {
    return api.post(`/ops/users`, params).then((res) => {
      return res.data.data
    })
  },
  delWheelUser (params) {
    return api.delete(`/ops/users/wheel`, params).then((res) => {
      return res.data.data
    })
  },
  findUserToWheel (params) {
    return api.get(`/ops/users/search?q=${params.key}`).then((res) => {
      return res.data.data
    })
  },
}
