import apiReport from '@/api/report'
import * as types from '@/store/mutation-types'

interface State {
  completion: any[],
  distribution: any[]
  completionSum: number
}

let state: State = {
  completion: [],
  distribution: [],
  completionSum: 0
}

const getters = {
  completion: (state: State) => state.completion,
  distribution: (state: State) => state.distribution,
  completionSum: (state: State) => state.completionSum
}

let mutations = {
  [types.REPORT_CASE_DETAIL] (state: State, data) {
    let res = data.data
    if (res.completion !== null) {
      state.completion = res.completion.groups
      state.completionSum = res.completion.sum
    } else {
      state.completion = []
      state.completionSum = 0
    }
    state.distribution = res.distribution
  }
}

let actions = {
  caseDetailReport ({ commit }: any, req: any) {
    let params = req.params
    return apiReport.caseDetailReport(params).then(data => {
      commit(types.REPORT_CASE_DETAIL, { data })
    })
  }
}

const MedicalCase = ({
  state,
  getters,
  actions,
  mutations
})

export default MedicalCase
