import axios from 'axios'
import qs from 'qs'
import bus from '@/common/bus'
import conf from '@/common/conf'

const instance = axios.create()

axios.defaults.baseURL = conf.baseUrl()
axios.defaults.headers['Content-Type'] = 'application/json'

instance.defaults.timeout = conf.Timeout

axios.interceptors.request.use((config: any) => {
  const Bearer = 'Bearer '
  if (localStorage.getItem('token')) config.headers.Authorization = Bearer + localStorage.getItem('token')
  axios.defaults.paramsSerializer = (params: any) => { return qs.stringify(params) }
  return config
}, (error) => { return Promise.reject(error) })

axios.interceptors.response.use((res: any) => {
  if (res.data && !res.data.success) {
    let errorMsg = res.data.error + ''
    bus.$emit('error', errorMsg)
  }
  return res
}, (error: any) => {
  if (!error.response) return
  console.log('error', error.response.data.error.message)
  let code = error.response.status
  let errMsg = error.response.data.error.message
  exceptionHandler(code, errMsg, error.response.data)
  return Promise.reject(error)
})

let api = (() => {
  return {
    get (url: string, params?: any) {
      return axios.get(url, params)
    },
    async post (url: string, params?: any, flag?: boolean) {
      if (flag) {
        await TimeoutHandler(flag)
      }
      return axios.post(url, params)
    },
    head (url: string, params?: any) {
      return axios.head(url, params)
    },
    delete (url: string, params?: any) {
      return axios.delete(url, {data: params})
    },
    put (url: string, params?: any) {
      return axios.put(url, params)
    },
    patch (url: string, params?: any) {
      return axios.patch(url, params)
    }
  }
})()

function TimeoutHandler(flag?: boolean) {
  if (flag) instance.defaults.timeout = conf.DownloadTimeout
}

function exceptionHandler(code: any, errMsg: string, errorResData: any) {
  const EXCEPTIONCODEMSG = conf.ExceptionCodeMsg
  let eCode = 'exception:' + code
  EXCEPTIONCODEMSG[eCode] !== undefined ? bus.$emit(eCode, errMsg) : bus.$emit('error', errorResData)
}

export default api
