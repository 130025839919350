import apiCase from '@/api/medicalCase'
import * as types from '@/store/mutation-types'

interface State {
  total: number,
  items: Object[],
  detail: object,
  fields: string[],
  newCaseId: number
}

let state: State = {
  total: 0,
  items: [],
  detail: {},
  fields: [],
  newCaseId: 0
}

const getters = {
  total: (state: State) => state.total,
  items: (state: State) => state.items,
  detail: (state: State) => state.detail,
  fields: (state: State) => state.fields,
  newCaseId: (state: State) => state.newCaseId
}

let mutations = {
  [types.MEDICALCASE_APPROVED] (state: State, data) {
    let res = data.data
    state.total = res.total
    state.items = res.items,
    state.fields = res.fields
  },
  [types.MEDICALCASE_DETAIL] (state: State, data) {
    state.detail = data.data
  },
  [types.MEDICALCASE_CREATE] (state: State, data) {
    state.newCaseId = data.data
  }
}

let actions = {
  caseList ({ commit }: any, req: any) {
    let params = req.params
    return apiCase.caseList(params).then(data => {
      commit(types.MEDICALCASE_APPROVED, { data })
    })
  },
  createCase ({ commit }: any, req: any) {
    let params = req.params
    return apiCase.createCase(params).then(data => {
      commit(types.MEDICALCASE_CREATE, { data })
    })
  },
  caseDetail ({ commit }: any, req: any) {
    let params = req.params
    return apiCase.caseDetail(params).then(data => {
      commit(types.MEDICALCASE_DETAIL, { data })
    })
  },
  caseReview ({ commit }: any, req: any) {
    let params = req.params
    return apiCase.caseReview(params)
  },
  caseReviewToPending ({ commit }: any, req: any) {
    let params = req.params
    return apiCase.caseReviewToPending(params).then(data => {
      commit(types.MEDICALCASE_DETAIL, { data })
    })
  },
  caseReviewBatch ({ commit }: any, req: any) {
    let params = req.params
    return apiCase.caseReviewBatch(params)
    // return apiCase.caseReviewBatch(params).then(data => {
    //   commit(types.MEDICALCASE_APPROVED, { data })
    // })
  },
  caseUpdate ({ commit }: any, req: any) {
    let params = req.params
    return apiCase.caseUpdate(params).then(data => {
      commit(types.MEDICALCASE_DETAIL, { data })
    })
  },
  caseDelBatch ({ commit }: any, req: any) {
    let params = req.params
    return apiCase.caseDelBatch(params)
  },
  caseDel ({ commit }: any, req: any) {
    let params = req.params
    return apiCase.caseDel(params)
  }
}

const MedicalCase = ({
  state,
  getters,
  actions,
  mutations
})

export default MedicalCase
