class Permission {
  private roles: object = {
    leader: [
      'dashboard-index', 'config-personal', 'config-password', 
      'member-index', 'member-managers', 'member-groups', 'case-index', 
      'case-pass', 'case-check', 'case-mine', 'report-index', 
      'export-index', 'export-search', 'export-log', 'ocr-index', 'temp-config-index', 
      'topic-config-index'
    ],
    professor: [
      'dashboard-index', 'config-personal', 'config-password', 
      'member-index', 'member-managers', 'member-groups', 'case-index', 
      'case-pass', 'case-check', 'case-mine', 'report-index', 
      'export-index', 'export-search', 'export-log', 'ocr-index'
    ],
    doctor: [
      'dashboard-index', 'config-personal', 'config-password', 
      'case-index', 'case-mine', 'report-index', 'export-index', 'export-search', 'export-log',
      'ocr-index'
    ]
  }
  public routeName: object = {
    'dashboard-index': '工作台',
    'config-personal': '个人信息',
    'config-password': '账号修改',
    'member-index': '成员管理',
    'member-managers': '管理员',
    'member-groups': '组员',
    'case-index': '病历管理',
    'case-pass': '病历列表',
    'case-check': '审核列表',
    'case-mine': '我的病例',
    'report-index': '报表管理',
    'export-index': '数据导出',
    'export-search': '筛选导出',
    'export-log': '导出记录',
    'temp-config-index': '模板配置',
    'topic-config-index': '课题配置'
  }
  // 根据不同的权限去展示不同的菜单
  public routerJudge(config) {
    // 需要展示导出记录 恒瑞定制版本的可查看导出列表
    if (!config.exportLog) {
      ['leader', 'professor', 'doctor'].map((item) => {
        this.removeRoute(this.roles, item, (this.roles as any)[item], 'export-log')
      })
    }
    // ideal系统 professor & doctor 不可查看导出数据模块
    if (config.exportModule) {
      ['professor', 'doctor'].map((item) => {
        this.removeRoute(this.roles, item, (this.roles as any)[item], 'export-index')
      })
    }
    let projects: any = localStorage.getItem('projects')
    if (projects) {
      projects = JSON.parse(projects)
      const role = projects.role
      return this.roles[role]
    }
  }
  // 去除某个元素
  private removeRoute(origin, type, arr, item) {
    origin[type] = arr.filter((v: string) => v !== item)
  }
}
export default new Permission()
