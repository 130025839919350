import api from '@/common/api'

/**
 * caseList
 * @param {*} params
 */
export default {
  getTemplate (params) {
    return api.get(`/projects/${params.projectId}/template`, { params }).then((res) => {
      return res.data.data
    })
  },
  editTempName (params) {
    return api.put(`/projects/${params.projectId}/template`, params).then((res) => {
      return res.data.data
    })
  },
  // group - add
  addFormGroup (params) {
    return api.post(`/projects/${params.projectId}/template/form-groups`, params).then((res) => {
      return res.data.data
    })
  },
  // group - update
  updateFormGroup (params) {
    return api.put(`/projects/${params.projectId}/form-groups/${params.formGroupId}`, params).then((res) => {
      return res.data.data
    })
  },
  // group form - add
  addFormGroupForm (params) {
    return api.post(`/projects/${params.projectId}/form-groups/${params.formGroupId}/forms`, params).then((res) => {
      return res.data.data
    })
  },
  // group form - update
  updateFormGroupForm (params) {
    return api.put(`/projects/${params.projectId}/forms/${params.formId}`, params).then((res) => {
      return res.data.data
    })
  },
  // add && update group form field
  updateFormGroupFormField (params) {
    return api.put(`/projects/${params.projectId}/forms/${params.formId}/fields`, params.data).then((res) => {
      return res.data.data
    })
  },
  // delete groups
  delFormGroup (params) {
    return api.delete(`/${params.type}/${params.id}`, params).then((res) => {
      return res.data.data
    })
  },
  // groups form 排序
  sortFormGroupForm (params) {
    return api.post(`/projects/${params.projectId}/forms-groups/sort`, params).then((res) => {
      return res.data.data
    })
  },
  // 复制Group
  copyGroup (params) {
    return api.post(`/projects/${params.projectId}/template/form-groups/${params.groupId}`, params).then((res) => {
      return res.data.data
    })
  },
   // 复制Group form
   copyGroupForm (params) {
    return api.post(`/projects/${params.projectId}/form-groups/${params.groupId}/forms/${params.formId}`, params).then((res) => {
      return res.data.data
    })
  }
}
