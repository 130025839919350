

















import { Component, Vue, Watch } from 'vue-property-decorator'
import dashboardHeader from '@/components/dashboard/header.vue'
import dashboardAside from '@/components/dashboard/aside.vue'
import conf from '@/common/conf'
import { Getter, Action } from 'vuex-class'

@Component({
  components: {
    dashboardHeader,
    dashboardAside
  }
})
export default class App extends Vue {
  @Getter('asideConfigs') asideConfigs: Object
  @Action('asideConfig') asideConfig: Function

  asideEnabled: boolean = false
  headerEnabled: boolean = false
  asides: any[] = ['topicAdd-index', 'login', 'forget-password', 'staff-account-index', 'personal-index', 'data-overview-index']
  headers: any[] = ['login', 'forget-password']
  skin: string = ''
  newLists: any = {}
  created () {
    this.asideConfig()
  }
  @Watch('asideConfigs')
  onAsideConfigsChange(val: any, old: any) {
    this.newLists = Object.assign({}, val)
  }
  @Watch('$route.name')
  routeNameChange (val: any, old: any) {
    this.asideEnabled = this.asides.includes(val) ? false : true
    this.headerEnabled = this.headers.includes(val) ? false : true
  }
}
