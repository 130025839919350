










































import { Component, Vue, Watch, Emit, Prop } from "vue-property-decorator"
import permission from '@/common/permission'
import bus from '@/common/bus'
import conf from '@/common/conf'

@Component({
  props: {
    asideConfigs: Object
  }
})
export default class DashboardAside extends Vue {
  member_type: string = ''
  logined: boolean = false
  visible = false
  routeName: Object = permission.routeName
  @Prop()
  asideConfigs: object

  routes: string[] = []
  bgColor: string = ''
  project: any = JSON.parse(localStorage.getItem("projects"))

  created () {
    this.getRoutes()
    this.getAsideBg()
  }
  // ideal系统 管理员和组员不可见<数据导出>模块
  get isIdeal () {
    return conf.judgeDomation() === 'ideal'
  }
  getAsideBg () {
    this.bgColor = conf.judgeDomation() === 'image' ? '#409EFF' : '#32c8a6'
  }
  getRoutes () {
    this.routes = permission.routerJudge({
      exportLog: (this.asideConfigs as any).export_log,
      exportModule: this.isIdeal
    })
  }
  handleOpen(val: string) {
    bus.$emit('memberChange', val)
  }
}
