import api from '@/common/api'

/**
 * caseList
 * @param {*} params
 */

export default {
  usersList(params) {
    return api.get(`/projects/${params.projectId}/users`, { params }).then((res) => {
      return res.data.data
    })
  },
  usersDetail(params) {
    return api.get(`/projects/${params.projectId}/users/${params.id}`).then((res) => {
      return res.data.data
    })
  },
  groupsList(params) {
    return api.get(`/projects/${params.projectId}/users`, { params }).then((res) => {
      return res.data.data
    })
  },
  usersDelete(params) {
    return api.delete(`/projects/${params.projectId}/users/${params.id}`, { params }).then((res) => {
      return res.data.data
    })
  },
  usersUpdate(params) {
    return api.put(`/projects/${params.projectId}/users/${params.id}`, params).then((res) => {
      return res.data
    })
  },
  profileUpdate(params) {
    return api.put(`/profile`, params).then((res) => {
      return res.data
    })
  },
  forbiddenUser(params) {
    return api.put(`/projects/${params.projectId}/users/status/batch`, params).then((res) => {
      return res.data.data
    })
  },
  changeRole(params) {
    return api.put(`/projects/${params.projectId}/users/role/batch`, params).then((res) => {
      return res.data.data
    })
  },
  addUser (params) {
    return api.post(`/projects/${params.projectId}/users`, params.query).then((res) => {
      return res.data.data
    })
  }
}
