import apiDataView from '@/api/dataView'
import * as types from '@/store/mutation-types'

interface State {
  dataviewHistory: object,
  dataviewStatus: any[],
  dataviewTotal: number,
  dataviewDoctorTotal: number,
  dataviewDoctorStatus: any[],
}

let state: State = {
  dataviewHistory: {
    approved: [],
    created: [],
    series: []
  },
  dataviewStatus: [],
  dataviewTotal: 0,
  dataviewDoctorTotal: 0,
  dataviewDoctorStatus: []
}

const getters = {
  dataviewHistory: (state: State) => state.dataviewHistory,
  dataviewStatus: (state: State) => state.dataviewStatus,
  dataviewTotal: (state: State) => state.dataviewTotal,
  dataviewDoctorStatus: (state: State) => state.dataviewDoctorStatus,
  dataviewDoctorTotal: (state: State) => state.dataviewDoctorTotal
}

let mutations = {
  [types.DATA_VIEW_CASE] (state: State, data) {
    let res = data.data
    state.dataviewHistory = res.history
    state.dataviewStatus = res.status
    state.dataviewTotal = res.total
  },
  [types.DATA_VIEW_USER] (state: State, data) {
    let res = data.data
    state.dataviewDoctorStatus = res.status
    state.dataviewDoctorTotal = res.total
  },
  [types.DATA_VIEW_CLEAR] (state: State) {
    state.dataviewHistory = {}
    state.dataviewStatus = []
    state.dataviewTotal = 0
    state.dataviewDoctorStatus = []
    state.dataviewDoctorTotal = 0
  }
}

let actions = {
  dataViewStatsCase ({ commit }: any, req: any) {
    let params = req.params
    return apiDataView.dataViewStatsCase(params).then(data => {
      commit(types.DATA_VIEW_CASE, { data })
    })
  },
  dataViewStatsUser ({ commit }: any, req: any) {
    let params = req.params
    return apiDataView.dataViewStatsUser(params).then(data => {
      commit(types.DATA_VIEW_USER, { data })
    })
  },
  clearDataViewData ({ commit }: any) {
    commit(types.DATA_VIEW_CLEAR)
  }
}

const DataView = ({
  state,
  getters,
  actions,
  mutations
})

export default DataView
