import api from '@/common/api'

/**
 * stats case / user
 * @param {*} params
 */
export default {
  dataViewStatsCase (params) {
    return api.get(`/ops/projects/${params.projectId}/stats/case`).then((res) => {
      return res.data.data
    })
  },
  dataViewStatsUser (params) {
    return api.get(`/ops/projects/${params.projectId}/stats/user`).then((res) => {
      return res.data.data
    })
  }
}
