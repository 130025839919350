import api from '@/common/api'

/**
 * caseList
 * @param {*} params
 */
export default {
  doImport (params) {
    return api.post(`/projects/${params.projectId}/cases/import/`, params).then((res) => {
      return res.data.data
    })
  },
  downloadImpTemp (params) {
    return api.get(`/projects/${params.projectId}/import-template`, params).then((res) => {
      return res.data.data
    })
  }
}
