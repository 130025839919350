import apiExport from '@/api/export'
import * as types from '@/store/mutation-types'

interface State {
  searchField: object[],
  searchList: object[],
  exportUrl: string,
  exportLog: object[],
  logTotal: number
}

const state: State = {
  searchField: [],
  searchList: [],
  exportUrl: '',
  exportLog: [],
  logTotal: 0
}

const getters = {
  searchField: (state: State) => state.searchField,
  searchList: (state: State) => state.searchList,
  exportUrl: (state: State) => state.exportUrl,
  exportLog: (state: State) => state.exportLog,
  logTotal: (state: State) => state.logTotal
}

let mutations = {
  [types.ADVANCED_SEARCH_FIELD] (state: State, data) {
    let res = data.data
    state.searchField = res
  },
  [types.ADVANCED_SEARCH_LIST] (state: State, data) {
    let res = data.data
    state.searchList = res
  },
  [types.DO_EXPORT_URL] (state: State, data) {
    let res = data.data
    state.exportUrl = res
  },
  [types.EXPORT_LOG] (state: State, data) {
    let res = data.data
    state.exportLog = res.items
    state.logTotal = res.total
  }
}

let actions = {
  advancedSearchFields ({ commit }: any, req: any) {
    let params = req.params
    return apiExport.advancedSearchFields(params).then(data => {
      commit(types.ADVANCED_SEARCH_FIELD, { data })
    })
  },
  advancedSearch ({ commit }: any, req: any) {
    let params = req.params
    return apiExport.advancedSearch(params).then(data => {
      commit(types.ADVANCED_SEARCH_LIST, { data })
    })
  },
  doExport ({ commit }: any, req: any) {
    let params = req.params
    return apiExport.doExport(params).then(data => {
      commit(types.DO_EXPORT_URL, { data })
    })
  },
  getExportLog ({ commit }: any, req: any) {
    let params = req.params
    return apiExport.exportLog(params).then(data => {
      commit(types.EXPORT_LOG, { data })
    })
  }
}

const exportCase = ({
  state,
  getters,
  actions,
  mutations
})

export default exportCase
