import { Vue, Component } from 'vue-property-decorator'
/**
 * Mixin test
 *
 * @export
 * @class GlobalMethodsMixin
 * @extends {Vue}
 */
@Component({})
export default class GlobalMethodsMixin extends Vue {
  public successMsg(msg: any) {
    this.$message({
      message: msg,
      type: 'success'
    })
  }
  public errorMsg(msg: any) {
    this.$message({
      message: msg,
      type: 'error'
    })
  }
  public warnMsg(msg: any) {
    this.$message({
      message: msg,
      type: 'warning'
    })
  }
}
