import api from '@/common/api'
/**
 * login
 * @param {*} params
 */
export default {
  Login (params: any) {
    return api.post(`/sign-in`, params).then((res) => {
      return res.data.data
    })
  },
  UpdatePassword (params: object) {
    return api.put(`/profile/password`, params).then((res) => {
      return res.data
    })
  },
  Profile () {
    return api.get(`/profile`).then((res) => {
      return res.data.data
    })
  },
  VerifyCode (params: object) {
    return api.post(`/verify-code`, params).then((res) => {
      return res.data
    })
  },
  ResetPassword (params: object) {
    return api.post(`/reset-password`, params).then((res) => {
      return res.data
    })
  }
}