import apiLogin from '@/api/login'
import * as types from '@/store/mutation-types'

interface State {
  token: string
  account: Object
  projects: any[],
  other_projects: any[],
  allProjs: any[]
}

let state: State = {
  token: '',
  account: {},
  projects: [],
  other_projects: [],
  allProjs: []
}

const getters = {
  account: (state: State) => state.account,
  projects: (state: State) => state.projects,
  otherProjects: (state: State) => state.other_projects,
  token: (state: State) => state.token,
  allProjs: (state: State) => state.allProjs
}

let mutations = {
  [types.COMMON_TOKEN_CHANGE] (state: State, token: string) {
    state.token = token
  },
  [types.COMMON_ACCOUNT] (state: State, { data }: any) {
    state.other_projects = data.other_projects
    state.projects = data.projects
    state.account = {
      id: data.id,
      phone: data.phone,
      position: data.position,
      name: data.name,
      is_staff: data.is_staff,
      gender: data.gender,
      email: data.email,
      is_wheel: data.is_wheel
    }
    if (data.projects && data.other_projects) {
      state.allProjs = [...data.other_projects, ...data.projects]
    } else if (data.projects && !data.other_projects) {
      state.allProjs = [...data.projects]
    } else if (!data.projects && data.other_projects) {
      state.allProjs = [...data.other_projects]
    } else {
      state.allProjs = []
    }
    state.token = data.token ? data.token : state.token
  }
}

let actions = {
  login ({ commit }: any, req: any) {
    let params = req.params
    return apiLogin.Login(params).then(data => {
      commit(types.COMMON_ACCOUNT, { data, params })
    })
  },
  updatePassword ({ commit }: any, req: any) {
    let params = req.params
    return apiLogin.UpdatePassword(params)
  },
  profile ({ commit }: any) {
    return apiLogin.Profile().then(data => {
      commit(types.COMMON_ACCOUNT, { data })
    })
  },
  verifyCode ({ commit }: any, req: any) {
    let params = req.params
    return apiLogin.VerifyCode(params)
  },
  resetPassword ({ commit }: any, req: any) {
    let params = req.params
    return apiLogin.ResetPassword(params)
  }
}
const Login = ({
  state,
  getters,
  actions,
  mutations
})

export default Login
