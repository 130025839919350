












































import { Component, Vue } from 'vue-property-decorator'
import bus from '@/common/bus'
import globalMixins from '@/mixins/global.ts'
import conf from '@/common/conf'
import { Getter } from "vuex-class"

@Component({
  mixins: [globalMixins]
})
export default class Header extends Vue<globalMixins> {
  @Getter('asideConfigs') asideConfigs: Object
  visible: boolean = false
  account: object = null
  logoUrl: string = ''
  logoTitle: string = '专家版管理软件'
  backIcon: boolean = false
  // 判断是否具有wheel权限, image域名下开通此权限
  wheel: boolean = false
  created () {
    this.account = JSON.parse(localStorage.getItem('account'))
    bus.$on('accountChange', () => {
      this.account = JSON.parse(localStorage.getItem('account'))
    })
    this.logoConf()
  }
  get isWheel () {
    return (this.account as any).is_staff && this.wheel
  }
   // ideal系统 数据概览模块
  get isDataOverView () {
    return (this.account as any).is_staff && (this.asideConfigs as any).staff_stats
  }
  commandClick (val) {
    if (val === 'account') {
      this.$router.push('/staff-account')
    } else if(val === 'dataOverview') {
      this.$router.push('/data-overview')
    } else {
      this.$router.push('/personal')
    }
  }
  logoConf () {
    switch (conf.judgeDomation()) {
      case 'image':
        this.logoUrl = require('@/assets/images/imageLogin/logo_image.png')
        this.wheel = true
        break
      case 'ideal':
        this.logoUrl = require('@/assets/images/ideal-logo.png')
        this.logoTitle = '系统主界面'
        this.backIcon = true
        this.wheel = false
        break
      default:
        this.logoUrl = require('@/assets/images/logo.png')
        this.wheel = false
        break
    }
  }
  logout () {
    this.visible = false
    localStorage.removeItem('account')
    localStorage.removeItem('token')
    localStorage.removeItem('projects')
    this.successMsg('退出成功！')
    this.$router.push('/')
  }
  goToTopic () {
    this.$router.push({name: 'topicAdd-index'})
  }
  personal () {
    this.$router.push({name: 'config-personal'})
  }
}
