import apiTopic from '@/api/topicAdd'
import * as types from '@/store/mutation-types'

interface State {
  proj: object[],
  otherProj: object[],
  userInfoLists: object[],
  copyInfo: object
}

let state: State = {
  proj: [],
  otherProj: [],
  userInfoLists: [],
  copyInfo: {}
}

const getters = {
  proj: (state: State) => state.proj,
  otherProj: (state: State) => state.otherProj,
  userInfoLists: (state: State) => state.userInfoLists,
  copyInfo: (state: State) => state.copyInfo
}

let mutations = {
  [types.USER_PROFILE] (state: State, data) {
    state.proj = data.data.projects
    state.otherProj = data.data.other_projects
  },
  [types.USER_INFO] (state: State, data) {
    state.userInfoLists = data.data
  },
  [types.COPY_INFO] (state: State, data) {
    state.copyInfo = data.data
  }
}

let actions = {
  userProfile ({ commit }: any, req: any) {
    return apiTopic.userProfile().then(data => {
      commit(types.USER_PROFILE, { data })
    })
  },
  findUser ({ commit }: any, req: any) {
    let params = req.params
    return apiTopic.findUser(params).then(data => {
      commit(types.USER_INFO, { data })
    })
  },
  addUserToProject ({ commit }: any, req: any) {
    let params = req.params
    return apiTopic.addUserToProject(params)
  },
  copyProject ({ commit }: any, req: any) {
    let params = req.params
    return apiTopic.copyProject(params).then(data => {
      commit(types.COPY_INFO, { data })
    })
  },
  updateProjectTitle ({ commit }: any, req: any) {
    let params = req.params
    return apiTopic.updateProjectTitle(params).then(data => {
      commit(types.COPY_INFO, { data })
    })
  },
  delUser ({ commit }: any, req: any) {
    let params = req.params
    return apiTopic.delUser(params)
  },
  delProject ({ commit }: any, req: any) {
    let params = req.params
    return apiTopic.delProject(params)
  }
}
const TopicAdd = ({
  state,
  getters,
  actions,
  mutations
})

export default TopicAdd
