import api from '@/common/api'

/**
 * caseList
 * @param {*} params
 */
export default {
  caseList(params) {
    return api.get(`/projects/${params.projectId}/cases`, { params }).then((res) => {
      return res.data.data
    })
  },
  caseDetail(params) {
    return api.get(`/projects/${params.projectId}/cases/${params.id}`).then((res) => {
      return res.data.data
    })
  },
  // 转为驳回 || 审核通过
  caseReview(params) {
    return api.put(`/projects/${params.projectId}/cases/${params.id}/review`, params).then((res) => {
      return res.data.data
    })
  },
  // 转为驳回 || 审核通过 批量
  caseReviewBatch (params) {
    return api.put(`/projects/${params.projectId}/cases/review/batch`, params).then((res) => {
      return res.data.data
    })
  },
  // 更新case detail
  caseUpdate (params) {
    let caseId = params.caseId
    let query = params.query
    return api.put(`/projects/${params.projectId}/cases/${caseId}`, query).then((res) => {
      return res.data.data
    })
  },
  // 删除case 批量
  caseDelBatch (params) {
    return api.delete(`/projects/${params.projectId}/cases/batch`, params).then((res) => {
      return res.data.data
    })
  },
  // 删除case 单个
  caseDel (params) {
    return api.delete(`/projects/${params.projectId}/cases/${params.id}`).then((res) => {
      return res.data.data
    })
  },
  // 提交审核
  caseReviewToPending (params) {
    return api.post(`/projects/${params.projectId}/cases/${params.id}/review`).then((res) => {
      return res.data.data
    })
  },
  // 新增病历
  createCase (params) {
    return api.post(`/projects/${params.projectId}/cases`).then((res) => {
      return res.data.data
    })
  }
}
